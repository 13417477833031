import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MotionBox } from '../../components/common/Motion/Motion';
import SectionTitle from '../../components/common/SectionTitle/SectionTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DataObjectIcon from '@mui/icons-material/DataObject';
import StorageIcon from '@mui/icons-material/Storage';
import BugReportIcon from '@mui/icons-material/BugReport';
import SchoolIcon from '@mui/icons-material/School';

const AnimationWrapper = styled(MotionBox)({});

const ServiceCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: theme.palette.background.default, // Use the theme's default background color
  border: `1px solid ${theme.palette.sage}`,
  marginBottom: theme.spacing(4),
  transition: 'all 0.3s ease-in-out', // Adding a smooth transition effect
  '&:hover': {
    boxShadow: theme.shadows[6], // Slight shadow effect on hover
    transform: 'scale(1.05)', // Slight scaling effect on hover
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.brown,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: '3rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`service-tabpanel-${index}`}
      aria-labelledby={`service-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
};

const services = [
  {
    icon: <DataObjectIcon fontSize="inherit" />,
    title: 'Data Annotation',
    description: 'Comprehensive data annotation services for AI/ML models',
    features: [
      'Bounding box and polygon annotations',
      'Semantic segmentation',
      'Key point annotation',
      'Text annotation and classification',
      'Video annotation and tracking',
      'Multi-level attribute tagging',
    ],
    details: 'Our data annotation service provides high-quality, accurate labeling for various AI and machine learning applications. We specialize in computer vision, natural language processing, and autonomous vehicle datasets.',
  },
  {
    icon: <StorageIcon fontSize="inherit" />,
    title: 'Data Curation',
    description: 'Expert data curation and quality enhancement services',
    features: [
      'Data cleaning and preprocessing',
      'Dataset de-duplication',
      'Data validation and verification',
      'Quality assurance checks',
      'Dataset balancing',
      'Custom data filtering',
    ],
    details: 'We help organizations maintain high-quality datasets through our comprehensive data curation services. Our team ensures data accuracy, consistency, and relevance for your specific use case.',
  },
  {
    icon: <BugReportIcon fontSize="inherit" />,
    title: 'Testing Services',
    description: 'Comprehensive software testing solutions',
    features: [
      'Manual testing',
      'Automated testing with Selenium',
      'Performance testing',
      'API testing',
      'Mobile app testing',
      'Cross-browser compatibility testing',
    ],
    details: 'Our testing services ensure your applications meet the highest quality standards. We provide end-to-end testing solutions including functional, performance, and compatibility testing.',
  },
  {
    icon: <SchoolIcon fontSize="inherit" />,
    title: 'Training Data',
    description: 'Custom training data creation for AI models',
    features: [
      'Custom dataset creation',
      'Data augmentation',
      'Synthetic data generation',
      'Domain-specific datasets',
      'Multilingual data collection',
      'Ground truth validation',
    ],
    details: 'We create high-quality training datasets tailored to your specific AI model requirements. Our team ensures balanced, diverse, and accurately labeled data for optimal model performance.',
  },
];

const Services = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <SectionTitle
          title="Our Services"
          subtitle="Comprehensive data solutions for AI development"
        />

        <AnimationWrapper
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="services tabs"
            >
              {services.map((service, index) => (
                <StyledTab
                  key={index}
                  label={service.title}
                  id={`service-tab-${index}`}
                  aria-controls={`service-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </Box>

          {services.map((service, index) => (
            <TabPanel key={index} value={value} index={index}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <AnimationWrapper
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                  >
                    <ServiceCard>
                      <CardContent>
                        <IconWrapper>{service.icon}</IconWrapper>
                        <Typography variant="h4" color="brown" gutterBottom align="center">
                          {service.title}
                        </Typography>
                        <Typography color="olive" paragraph align="center">
                          {service.description}
                        </Typography>
                      </CardContent>
                    </ServiceCard>
                  </AnimationWrapper>
                </Grid>
                <Grid item xs={12} md={8}>
                  <AnimationWrapper
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                  >
                    <ServiceCard>
                      <CardContent>
                        <Typography variant="h6" color="brown" gutterBottom>
                          Key Features
                        </Typography>
                        <List>
                          {service.features.map((feature, idx) => (
                            <ListItem key={idx}>
                              <ListItemIcon>
                                <CheckCircleOutlineIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText
                                primary={feature}
                                primaryTypographyProps={{ color: 'olive' }}
                              />
                            </ListItem>
                          ))}
                        </List>
                        <Typography variant="body1" color="primary" sx={{ mt: 2 }}>
                          {service.details}
                        </Typography>
                      </CardContent>
                    </ServiceCard>
                  </AnimationWrapper>
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </AnimationWrapper>
      </Container>
    </Box>
  );
};

export default Services;
