import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Link,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MotionBox } from '../../components/common/Motion/Motion';
import SectionTitle from '../../components/common/SectionTitle/SectionTitle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useFormik } from 'formik';
import * as yup from 'yup';

// Styled components
const AnimationWrapper = styled(MotionBox)({
  height: '100%',
});

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
  border: `1px solid ${theme.palette.grey[200]}`,
  overflow: 'hidden',
}));

const ContactFormWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const SocialIconButton = styled(Link)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40, // Increased size of the icon
  height: 40, // Increased size of the icon
  borderRadius: '50%',
  backgroundColor: theme.palette.sage,
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2), // Added margin to separate icon from text
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.olive,
    transform: 'translateY(-3px)',
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(3),
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    fontSize: '1.5rem',
  },
}));

// Validation schema using Yup
const validationSchema = yup.object({
  name: yup.string()
    .required('Name is required')
    .min(2, 'Name should be at least 2 characters'),
  email: yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  subject: yup.string()
    .required('Subject is required')
    .min(5, 'Subject should be at least 5 characters'),
  message: yup.string()
    .required('Message is required')
    .min(20, 'Message should be at least 20 characters'),
});

const Contact = () => {
  const theme = useTheme();
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitStatus('submitting');
      try {
        const response = await fetch('/api/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (!response.ok) {
          throw new Error('Failed to send email');
        }

        setSubmitStatus('success');
        resetForm();
        setTimeout(() => setSubmitStatus('idle'), 3000);
      } catch (error) {
        console.error('Error sending email:', error);
        setSubmitStatus('error');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box sx={{ py: { xs: 4, md: 8 }, backgroundColor: 'background.default' }}>
      <Container maxWidth="lg">
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <SectionTitle
            title="Get in Touch"
            subtitle="We'd love to hear from you"
          />
        </MotionBox>

        <Grid container spacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12} md={7}>
            <AnimationWrapper
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <StyledCard>
                <ContactFormWrapper>
                  <Typography variant="h5" color="primary" gutterBottom>
                    Send us a Message
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label="Your Name"
                          variant="outlined"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email Address"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="subject"
                          name="subject"
                          label="Subject"
                          variant="outlined"
                          value={formik.values.subject}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.subject && Boolean(formik.errors.subject)}
                          helperText={formik.touched.subject && formik.errors.subject}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="message"
                          name="message"
                          label="Message"
                          multiline
                          rows={6}
                          variant="outlined"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.message && Boolean(formik.errors.message)}
                          helperText={formik.touched.message && formik.errors.message}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={formik.isSubmitting || submitStatus === 'submitting'}
                          sx={{ py: 1.5 }}
                        >
                          {submitStatus === 'submitting' ? 'Sending...' : 'Send Message'}
                        </Button>
                      </Grid>
                      {submitStatus === 'success' && (
                        <Grid item xs={12}>
                          <Typography color="success" align="center">
                            Message sent successfully!
                          </Typography>
                        </Grid>
                      )}
                      {submitStatus === 'error' && (
                        <Grid item xs={12}>
                          <Typography color="error" align="center">
                            Failed to send message. Please try again later.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </ContactFormWrapper>
              </StyledCard>
            </AnimationWrapper>
          </Grid>

          <Grid item xs={12} md={5}>
            <AnimationWrapper
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <StyledCard sx={{ height: '100%' }}>
                <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                      Contact Information
                    </Typography>
                    <InfoItem>
                      <EmailIcon />
                      <Box>
                        <Typography variant="subtitle1" color="olive">
                          Email
                        </Typography>
                        <Link
                          href="mailto:naveen@benakaitsolutions.com"
                          color="primary"
                          sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                        >
                          naveen@benakaitsolutions.com
                        </Link>
                      </Box>
                    </InfoItem>
                    <InfoItem>
                      <PhoneIcon />
                      <Box>
                        <Typography variant="subtitle1" color="olive">
                          Phone
                        </Typography>
                        <Link
                          href="tel:+91-9632431818"
                          color="primary"
                          sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                        >
                          +91-9880622852
                        </Link>
                      </Box>
                    </InfoItem>
                    <InfoItem>
                      <LocationOnIcon />
                      <Box>
                        <Typography variant="subtitle1" color="olive">
                          Location
                        </Typography>
                        <Typography color="textPrimary">
                          Kumta Karnataka, India
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          {/* OpenStreetMap Embed */}
                          <iframe
                            title="Location Map"
                            src="https://www.openstreetmap.org/export/embed.html?bbox=77.57556823931904%2C12.986991567320467%2C77.60027313232422%2C13.001139946210882&layer=mapnik"
                            style={{ border: '0', width: '100%', height: '300px' }}
                            allowFullScreen={true} // Set to true
                            loading="lazy"
                          />

                        </Box>
                      </Box>
                    </InfoItem>
                  </Box>
                  <Divider sx={{ my: 3 }} />

                  <Box>
                    <Typography variant="h6" color="primary" gutterBottom>
                      Business Hours
                    </Typography>
                    <Typography color="olive" sx={{ mb: 1 }}>
                      Monday - Friday: 9:00 AM - 6:00 PM IST
                    </Typography>
                    <Typography color="olive" sx={{ mb: 1 }}>
                      Saturday: 9:00 AM - 1:00 PM IST
                    </Typography>
                    <Typography color="olive">
                      Sunday: Closed
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}> {/* Added marginTop to move down */}
                      <Typography variant="h5" color="primary" gutterBottom>
                        Follow Us
                      </Typography>
                      <Link href="https://in.linkedin.com/company/benaka-it-solutions-kumta" target="_blank">
                        <LinkedInIcon sx={{ fontSize: '2rem', color: 'primary.main' }} /> {/* Increased icon size */}
                      </Link>
                    </Box>
                  </Box>

                </CardContent>
              </StyledCard>
            </AnimationWrapper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
