import React from 'react';
import { Box, Grid, CardContent, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../common/SectionTitle/SectionTitle';
import { MotionCard } from '../../common/Motion/Motion';
import DataObjectIcon from '@mui/icons-material/DataObject';
import StorageIcon from '@mui/icons-material/Storage';
import BugReportIcon from '@mui/icons-material/BugReport';
import SchoolIcon from '@mui/icons-material/School';

// Styled components
const StyledMotionCard = styled(MotionCard)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: `0 8px 20px rgba(0, 0, 0, 0.15)`,
  },
  background: theme.palette.background.default, // Inherit background color (white)
  border: `1px solid ${theme.palette.primary.main}`, // Use primary color for border
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  '&:active': {
    transform: 'translateY(4px)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  fontSize: '3rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  transition: 'color 0.3s ease-in-out',
  '&:hover': {
    color: theme.palette.primary.light,
  },
}));

// Define types for the service items
interface Service {
  icon: JSX.Element;
  title: string;
  description: string;
}

const services: Service[] = [
  {
    icon: <DataObjectIcon fontSize="inherit" />,
    title: 'Data Annotation',
    description: 'Key points, rectangle, polygon, attributes, text, and more annotation services.',
  },
  {
    icon: <StorageIcon fontSize="inherit" />,
    title: 'Data Curation',
    description: 'Data de-duplication, categorization, and quality enhancement services.',
  },
  {
    icon: <BugReportIcon fontSize="inherit" />,
    title: 'Testing Services',
    description: 'Manual and automated testing with tools like Selenium.',
  },
  {
    icon: <SchoolIcon fontSize="inherit" />,
    title: 'Training Data',
    description: 'Create robust AI models with accurate, curated training datasets.',
  },
];

const ServicesSection: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <SectionTitle
          title="Our Services"
          subtitle="Comprehensive data solutions for AI development"
        />
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <StyledMotionCard
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <IconWrapper>{service.icon}</IconWrapper>
                  <Typography variant="h6" color="brown" gutterBottom>
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="olive">
                    {service.description}
                  </Typography>
                </CardContent>
              </StyledMotionCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicesSection;
