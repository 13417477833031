import { Box, Card } from '@mui/material';
import { motion, HTMLMotionProps } from 'framer-motion';
import { forwardRef } from 'react';

const BoxComponent = forwardRef((props: any, ref) => <Box {...props} ref={ref} />);
const CardComponent = forwardRef((props: any, ref) => <Card {...props} ref={ref} />);

export const MotionBox = motion(BoxComponent);
export const MotionCard = motion(CardComponent);

export type MotionBoxProps = HTMLMotionProps<"div">;
