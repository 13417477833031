import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../components/common/SectionTitle/SectionTitle';
import { MotionBox } from '../components/common/Motion/Motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AnimationWrapper = styled(MotionBox)({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.sage}`,
  boxShadow: theme.shadows[2],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const PriceText = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const PriceNote = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(3),
}));

const FeatureList = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Feature = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  '& svg': {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

const CTAButton = styled(Link)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(1.5),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textDecoration: 'none',
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const plans = [
  {
    name: 'Public',
    targetAudience: 'Personal work, open source projects, research',
    price: 'Free',
    creditCardRequired: 'No',
    credits: '$30 value in credits/month',
    additionalCredits: '$2 per additional credit',
    userSeats: '-',
    dataAndModels: 'Public',
    ctaText: 'Get Started for Free',
    ctaLink: '/signup',
    featured: false,
  },
  {
    name: 'Basic',
    targetAudience: 'Small teams building their first computer vision application',
    price: '$49',
    period: '/month',
    priceNote: 'Save 25% when billed annually',
    creditCardRequired: 'Yes',
    credits: '15 credits/month',
    additionalCredits: '$3 per additional credit',
    userSeats: '5 User Seats',
    dataAndModels: 'Private',
    ctaText: 'Get Started for Free',
    ctaLink: '/signup',
    featured: true,
  },
  {
    name: 'Growth',
    targetAudience: 'Startups and small businesses deploying to production',
    price: '$299',
    period: '/month',
    priceNote: 'Save 25% when billed annually',
    creditCardRequired: 'Yes',
    credits: '$90 value in credits/month',
    creditsOption: '$600 value in credits/month',
    additionalCredits: '$4 per additional credit',
    userSeats: '20 User Seats',
    dataAndModels: 'Private',
    ctaText: 'Get Started for Free',
    ctaLink: '/signup',
    featured: false,
  },
  {
    name: 'Enterprise',
    targetAudience: 'Scaled organizations with advanced deployment, security, and control needs',
    price: 'Custom',
    priceNote: 'Billed annually',
    creditCardRequired: 'Yes',
    credits: '360 credits loaded upfront',
    creditsOption: '1800 credits loaded upfront',
    additionalCredits: 'Custom credits',
    userSeats: 'Custom user limits with SSO',
    dataAndModels: 'Private',
    ctaText: 'Contact Sales',
    ctaLink: '/contact',
    featured: false,
  },
];

const PricingPage: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <AnimationWrapper
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box textAlign="center" mb={6}>
          <SectionTitle title="Pricing Plans" />
          <Typography variant="h6" color="text.secondary" mt={2}>
            Choose the plan that's right for you
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {plans.map((plan) => (
            <Grid item xs={12} md={6} lg={3} key={plan.name}>
              <AnimationWrapper
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <StyledPaper
                  elevation={plan.featured ? 8 : 2}
                  sx={{
                    border: (theme) =>
                      plan.featured
                        ? `2px solid ${theme.palette.primary.main}`
                        : undefined,
                  }}
                >
                  <PlanTitle variant="h5">{plan.name}</PlanTitle>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ minHeight: 48, mb: 3 }}
                  >
                    {plan.targetAudience}
                  </Typography>

                  <PriceText variant="h3">
                    {plan.price}
                    {plan.period && (
                      <Typography
                        component="span"
                        variant="h6"
                        color="text.secondary"
                      >
                        {plan.period}
                      </Typography>
                    )}
                  </PriceText>
                  {plan.priceNote && <PriceNote variant="body2">{plan.priceNote}</PriceNote>}

                  <FeatureList>
                    <Feature>
                      <CheckCircleIcon />
                      <Typography>Credits: {plan.credits}</Typography>
                    </Feature>
                    {plan.creditsOption && (
                      <Feature>
                        <CheckCircleIcon />
                        <Typography>{plan.creditsOption}</Typography>
                      </Feature>
                    )}
                    <Feature>
                      <CheckCircleIcon />
                      <Typography>Additional: {plan.additionalCredits}</Typography>
                    </Feature>
                    <Feature>
                      <CheckCircleIcon />
                      <Typography>{plan.userSeats}</Typography>
                    </Feature>
                    <Feature>
                      <CheckCircleIcon />
                      <Typography>{plan.dataAndModels} Data & Models</Typography>
                    </Feature>
                    <Feature>
                      <CheckCircleIcon />
                      <Typography>ML Labeling and Training</Typography>
                    </Feature>
                    <Feature>
                      <CheckCircleIcon />
                      <Typography>Workflow Builder</Typography>
                    </Feature>
                  </FeatureList>

                  <CTAButton to={plan.ctaLink}>{plan.ctaText}</CTAButton>
                </StyledPaper>
              </AnimationWrapper>
            </Grid>
          ))}
        </Grid>
      </AnimationWrapper>
    </Container>
  );
};

export default PricingPage;
