import React from 'react';
import { Box, Container, Grid, Typography, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../components/common/SectionTitle/SectionTitle';
import { MotionBox } from '../../components/common/Motion/Motion';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HistoryIcon from '@mui/icons-material/History';

const AnimationWrapper = styled(MotionBox)({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.sage}`,
  boxShadow: theme.shadows[2],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '2.5rem',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const Product = () => {
  const features = [
    {
      title: "AI-Assisted Annotation",
      description: "Advanced AI models assist in annotation tasks, improving accuracy and speed",
      icon: <AutoAwesomeIcon fontSize="large" />,
      benefits: [
        "Automated pre-annotations",
        "Smart object detection",
        "AI-powered suggestions",
        "Continuous learning system"
      ]
    },
    {
      title: "Batch Processing",
      description: "Efficient handling of multiple images simultaneously",
      icon: <CloudUploadIcon fontSize="large" />,
      benefits: [
        "Bulk image upload",
        "Batch annotation tools",
        "Multi-image processing",
        "Quick import/export"
      ]
    },
    {
      title: "Version Control",
      description: "Complete history tracking and management",
      icon: <HistoryIcon fontSize="large" />,
      benefits: [
        "Annotation history",
        "Undo/redo functionality",
        "Change tracking",
        "Version comparison"
      ]
    },
    {
      title: "Enterprise Security",
      description: "Top-tier security measures for your sensitive data",
      icon: <SecurityIcon fontSize="large" />,
      benefits: [
        "Client-side processing",
        "Secure data storage",
        "Access controls",
        "Data encryption"
      ]
    },
    {
      title: "High Performance",
      description: "Optimized for speed and efficiency",
      icon: <SpeedIcon fontSize="large" />,
      benefits: [
        "Fast processing",
        "Real-time updates",
        "Optimized workflow",
        "Resource efficiency"
      ]
    }
  ];

  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <SectionTitle
          title="ClearLabel.in"
          subtitle="Professional Image Annotation Tool"
        />

        <Box sx={{ mb: 6 }}>
          <Typography variant="h6" color="olive" align="center" sx={{ mb: 4 }}>
            Experience the next generation of image annotation with AI-powered assistance
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <AnimationWrapper
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <StyledPaper>
                  <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <FeatureIcon>{feature.icon}</FeatureIcon>
                    <Typography variant="h5" color="brown" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography color="olive" paragraph>
                      {feature.description}
                    </Typography>
                  </Box>
                  <List>
                    {feature.benefits.map((benefit, idx) => (
                      <ListItem key={idx} sx={{ py: 0.5 }}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText 
                          primary={benefit}
                          primaryTypographyProps={{ color: 'olive' }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </StyledPaper>
              </AnimationWrapper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Product;
