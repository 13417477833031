import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { theme } from './theme/theme';
import Layout from './components/layout/Layout/Layout';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Admin from './pages/Admin/Admin';
import Pricing from './pages/Pricing';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import Product from './pages/Product/Product';
import VisitTracker from './components/VisitTracker/VisitTracker';
import { initGA, logPageView } from './utils/analytics';

// Analytics wrapper component
function AnalyticsWrapper({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView();
    // Record page visit in database
    fetch('/api/record-visit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pageUrl: location.pathname }),
    }).catch(console.error);
  }, [location]);

  return <>{children}</>;
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AnalyticsWrapper>
            <Layout>
              <VisitTracker />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/product" element={<Product />} />
              </Routes>
            </Layout>
          </AnalyticsWrapper>
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
