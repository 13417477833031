import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../components/common/SectionTitle/SectionTitle';
import { MotionBox } from '../../components/common/Motion/Motion';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const AnimationWrapper = styled(MotionBox)({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.sage}`,
  boxShadow: theme.shadows[2],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

const TimelineItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(4),
  marginBottom: theme.spacing(4),
  opacity: 0,
  animation: 'fadeIn 1s forwards',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '2px',
    height: '100%',
    background: theme.palette.primary.main,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '-4px',
    top: '8px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
}));

const About = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <SectionTitle
          title="About Us"
          subtitle="Driving Innovation Since 2018"
        />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <AnimationWrapper
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <StyledPaper>
                <Typography variant="h5" color="brown" gutterBottom>
                  Our Story
                </Typography>
                <Typography color="olive" paragraph>
                  At Benaka IT Solutions, we specialize in Data Annotation as a Service, 
                  providing high-quality, curated training data for AI models. Since 2018, 
                  we've been at the forefront of enabling the AI revolution by bridging the 
                  gap between enterprises and their need for exceptional datasets.
                </Typography>
                <Typography color="olive">
                  Our innovative model utilizes rural talent for cost-effective, high-quality 
                  services while maintaining strict data security standards. We take pride in 
                  generating employment opportunities in rural Karnataka.
                </Typography>
              </StyledPaper>
            </AnimationWrapper>
          </Grid>

          <Grid item xs={12} md={6}>
            <AnimationWrapper
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <StyledPaper>
                <Typography variant="h5" color="brown" gutterBottom>
                  Our Journey
                </Typography>
                
                <TimelineItem>
                  <Typography variant="h6" color="brown" gutterBottom>
                    2018
                  </Typography>
                  <Typography color="olive">
                    Founded with a vision to empower rural talent in the tech industry
                  </Typography>
                </TimelineItem>

                <TimelineItem>
                  <Typography variant="h6" color="brown" gutterBottom>
                    2020
                  </Typography>
                  <Typography color="olive">
                    Reached milestone of 50 million images annotated
                  </Typography>
                </TimelineItem>

                <TimelineItem>
                  <Typography variant="h6" color="brown" gutterBottom>
                    2022
                  </Typography>
                  <Typography color="olive">
                    Partnered with Clonoid Private Limited for long-term projects
                  </Typography>
                </TimelineItem>

                <TimelineItem>
                  <Typography variant="h6" color="brown" gutterBottom>
                    2023
                  </Typography>
                  <Typography color="olive">
                    Expanded services to cover 10+ industries worldwide
                  </Typography>
                </TimelineItem>
              </StyledPaper>
            </AnimationWrapper>
          </Grid>
        </Grid>

        {/* Awards and Recognition Section */}
        <Box sx={{ mt: 8 }}>
          <SectionTitle
            title="Awards & Recognition"
            subtitle="Our Achievements"
          />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <AnimationWrapper
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledPaper>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                    <Typography variant="h5" color="brown">
                      Startup Karnataka Grant
                    </Typography>
                  </Box>
                  <Typography color="olive" paragraph>
                    Received prestigious Startup Karnataka Grant for the development of ClearLabel - our innovative AI-powered image annotation tool. This recognition highlights our commitment to advancing technology in the field of AI and machine learning.
                  </Typography>
                  <Typography color="olive" paragraph>
                    The grant supports our development of advanced features including:
                  </Typography>
                  <Box component="ul" sx={{ color: 'olive', ml: 2 }}>
                    <li>
                      <Typography color="olive">
                        AI-assisted annotation capabilities
                      </Typography>
                    </li>
                    <li>
                      <Typography color="olive">
                        Automated pre-annotation features
                      </Typography>
                    </li>
                    <li>
                      <Typography color="olive">
                        Smart object detection and tracking
                      </Typography>
                    </li>
                    <li>
                      <Typography color="olive">
                        Enhanced machine learning integration
                      </Typography>
                    </li>
                  </Box>
                </StyledPaper>
              </AnimationWrapper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
