import React from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MotionBox } from '../../common/Motion/Motion';

// Animation wrapper for the stats
const AnimationWrapper = styled(MotionBox)({});

const ContentText = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.primary.light, 
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[6],
  },
  textAlign: 'left', 
  color: theme.palette.brown, 
}));

const StatBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  color: theme.palette.brown, 
  backgroundColor: theme.palette.primary.light, 
  boxShadow: theme.shadows[3], 
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)', 
    boxShadow: theme.shadows[6], 
  },
}));

const stats = [
  {
    value: '100M+',
    label: 'Images & Videos Labeled',
  },
  {
    value: '5+',
    label: 'Years of Excellence',
  },
  {
    value: '10+',
    label: 'Industries Served',
  },
  {
    value: '100%',
    label: 'Client Satisfaction',
  },
];

const StatsSection = () => {
  const theme = useTheme();

  return (
    <Box sx={{ py: 8, bgcolor: theme.palette.primary.light }}>
      <Container>
        <Grid container spacing={4}>
          {/* New Section for Content on the Left */}
          <Grid item xs={12} sm={6} md={6}>
            <MotionBox
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: 'bold',
                  mb: 3,
                  textAlign: 'left', 
                  color: theme.palette.brown, 
                }}
              >
                Empowering the AI Revolution
              </Typography>
              <ContentText>
                ClearLabel by Benaka IT Solutions is your trusted partner for reliable, efficient, and cost-effective data annotation services. Our mission is to power the AI industry with high-quality training data, enabling smarter, more innovative solutions.
              </ContentText>
            </MotionBox>
          </Grid>

          {/* Stats Section - Right side with two columns */}
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={4} justifyContent="flex-end">
              {stats.map((stat, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <AnimationWrapper
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <StatBox>
                      <Typography
                        variant="h4"
                        component="div"
                        sx={{ fontWeight: 'bold', mb: 1 }}
                      >
                        {stat.value}
                      </Typography>
                      <Typography variant="body1" color="brown">
                        {stat.label}
                      </Typography>
                    </StatBox>
                  </AnimationWrapper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default StatsSection;