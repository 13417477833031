import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../components/common/SectionTitle/SectionTitle';
import { MotionBox } from '../../components/common/Motion/Motion';

const AnimationWrapper = styled(MotionBox)({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.sage}`,
  boxShadow: theme.shadows[2],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

const Terms: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <SectionTitle
          title="Terms of Service"
          subtitle="Rules and Responsibilities"
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AnimationWrapper
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <StyledPaper>
                <Typography paragraph color="olive">
                  Welcome to Benaka
                </Typography>

                <Typography paragraph color="olive">
                  These Terms of Service ("Terms") outline the rules and responsibilities for using Benaka's API Service. 
                  By accessing or using the service, you agree to comply with these Terms.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  1. Use of Service
                </Typography>
                <Typography paragraph color="olive">
                  You may only use the service in compliance with applicable laws and these Terms.
                </Typography>
                <Typography paragraph color="olive">
                  Misuse of the service or assisting others in misuse is strictly prohibited
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  2. API Usage and Limitations
                </Typography>
                <Typography paragraph color="olive">
                  Your API access is subject to rate limits and restrictions specified in our documentation.
                </Typography>
                <Typography paragraph color="olive">
                  We reserve the right to modify, suspend, or terminate the API without prior notice.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  3. Privacy and Security
                </Typography>
                <Typography paragraph color="olive">
                  You must comply with all relevant privacy and data protection laws.
                </Typography>
                <Typography paragraph color="olive">
                  Implement suitable security measures to safeguard data processed via the API.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  4. Intellectual Property
                </Typography>
                <Typography paragraph color="olive">
                  Benaka retains ownership of all rights to the API Service, including trademarks, logos, and domain names.
                </Typography>
                <Typography paragraph color="olive">
                  These Terms do not grant permission to use Benaka's brand features.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  5. Termination
                </Typography>
                <Typography paragraph color="olive">
                  Benaka may suspend or terminate your API access at any time, without notice, for any reason.
                </Typography>
                <Typography paragraph color="olive">
                  You may discontinue service use at any time.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  6. Disclaimers and Limitations of Liability
                </Typography>
                <Typography paragraph color="olive">
                  The service is provided "as is", with no express or implied warranties.
                </Typography>
                <Typography paragraph color="olive">
                  To the fullest extent allowed by law, Benaka disclaims liability for indirect, incidental, or consequential damages.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  7. Changes to These Terms
                </Typography>
                <Typography paragraph color="olive">
                  Benaka may update these Terms periodically, and the latest version will be available on our website.
                </Typography>
                <Typography paragraph color="olive">
                  Continued use of the service implies agreement to the revised Terms.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  8. Governing Law
                </Typography>
                <Typography paragraph color="olive">
                  These Terms are governed by the laws of the Jurisdiction, excluding conflict of law principles.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  Contact Us
                </Typography>
                <Typography paragraph color="olive">
                  For inquiries about these Terms, feel free to reach out to our team.
                </Typography>
                <Typography paragraph color="olive">
                  📧 terms@clearlabel.in   [Product of Benaka IT Solutions]
                </Typography>
              </StyledPaper>
            </AnimationWrapper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Terms;
