import ReactGA from 'react-ga';

// Initialize Google Analytics
export const initGA = () => {
  // Replace 'UA-XXXXXXXXX-X' with your actual Google Analytics tracking ID
  ReactGA.initialize('UA-XXXXXXXXX-X');
};

// Track page views
export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// Track events
export const logEvent = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

// Track user interactions
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
