import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../components/common/SectionTitle/SectionTitle';
import { MotionBox } from '../../components/common/Motion/Motion';

const AnimationWrapper = styled(MotionBox)({});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: theme.palette.background.default,
  border: `1px solid ${theme.palette.sage}`,
  boxShadow: theme.shadows[2],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

const Privacy: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container>
        <SectionTitle
          title="Privacy Policy"
          subtitle="How We Protect Your Data"
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AnimationWrapper
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <StyledPaper>
                <Typography paragraph color="olive">
                  Welcome to Clearlabel a Product of Benaka IT Solutions
                </Typography>

                <Typography paragraph color="olive">
                  This Privacy Policy outlines how Benaka manages your personal information when you use our Service.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  1. Information We Collect
                </Typography>
                <Typography paragraph color="olive">
                  We collect data you provide, including:
                </Typography>
                <Box component="ul" sx={{ pl: 4, color: 'olive' }}>
                  <Typography component="li" paragraph color="olive">
                    Name and contact details
                  </Typography>
                  <Typography component="li" paragraph color="olive">
                    Payment information
                  </Typography>
                  <Typography component="li" paragraph color="olive">
                    API usage and related data
                  </Typography>
                </Box>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  2. How We Use Your Information
                </Typography>
                <Typography paragraph color="olive">
                  Your data helps us:
                </Typography>
                <Box component="ul" sx={{ pl: 4, color: 'olive' }}>
                  <Typography component="li" paragraph color="olive">
                    Deliver and enhance our services
                  </Typography>
                  <Typography component="li" paragraph color="olive">
                    Process transactions and communicate updates
                  </Typography>
                  <Typography component="li" paragraph color="olive">
                    Provide technical support and respond to inquiries
                  </Typography>
                </Box>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  3. Data Retention & Security
                </Typography>
                <Typography paragraph color="olive">
                  We store your data only as long as necessary, applying robust security measures to protect it.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  4. Your Rights
                </Typography>
                <Typography paragraph color="olive">
                  You may have rights to:
                </Typography>
                <Typography paragraph color="olive">
                  Access, correct, or delete your data, based on your location.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  5. Policy Updates
                </Typography>
                <Typography paragraph color="olive">
                  We may revise this Privacy Policy. Changes will be posted here.
                </Typography>

                <Typography variant="h5" color="brown" gutterBottom sx={{ mt: 4 }}>
                  6. Contact Us
                </Typography>
                <Typography paragraph color="olive">
                  For privacy-related inquiries:
                </Typography>
                <Typography paragraph color="olive">
                  📧 privacy@clearlabel.in     [Product of Benaka IT Solutions]
                </Typography>
              </StyledPaper>
            </AnimationWrapper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Privacy;
