import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config/config';

const VisitTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const recordVisit = async () => {
      try {
        await fetch(`${config.API_BASE_URL}/api/record-visit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pageUrl: location.pathname }),
        });
      } catch (error) {
        console.error('Error recording visit:', error);
      }
    };

    recordVisit();
  }, [location.pathname]);

  return null;
};

export default VisitTracker;
