import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MotionBox } from '../Motion/Motion';

// Creating a styled wrapper around the MotionBox component
const AnimationWrapper = styled(MotionBox)({});

const TitleWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(6),
}));

const Underline = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '4px',
  background: theme.palette.primary.main,
  margin: '16px auto',
  borderRadius: '2px',
}));

interface SectionTitleProps {
  title: string;
  subtitle?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, subtitle }) => {
  return (
    <TitleWrapper>
      <AnimationWrapper
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <Typography variant="h2" color="brown" gutterBottom>
          {title}
        </Typography>
        <Underline />
        {subtitle && (
          <Typography variant="h6" color="olive">
            {subtitle}
          </Typography>
        )}
      </AnimationWrapper>
    </TitleWrapper>
  );
};

export default SectionTitle;
