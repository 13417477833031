import React from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  ButtonProps,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0 2px 4px rgba(103, 65, 136, 0.1)`,
  borderBottom: `1px solid ${theme.palette.sage}`,
}));

const StyledButton = styled(Button)<ButtonProps & { component?: typeof RouterLink; to?: string }>(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2),
  fontWeight: 600,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const LogoLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.brown,
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1),
}));

const DrawerList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  height: '100%',
}));

const ListItem = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    { text: 'Home', path: '/' },
    { text: 'About', path: '/about' },
    { text: 'Services', path: '/services' },
    { text: 'Product', path: '/product' },
    { text: 'Pricing', path: '/pricing' },
    { text: 'Contact', path: '/contact' },
  ];

  const drawer = (
    <Box sx={{
      width: 250,
      height: '100%',
    }}>
      <DrawerHeader>
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: '#FFFFFF' }}
        >
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerList>
        {navItems.map((item) => (
          <ListItem
            key={item.text}
            onClick={handleDrawerToggle}
          >
            <RouterLink
              to={item.path}
              style={{ textDecoration: 'none' }}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  sx: {
                    color: '#FFFFFF',
                    fontWeight: 500,
                  }
                }}
              />
            </RouterLink>
          </ListItem>
        ))}
      </DrawerList>
    </Box>
  );

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'flex-start',
          }}
        >
          <LogoLink to="/" sx={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
            <img
              src="/Logo.png"
              alt="ClearLabel Logo"
              style={{
                height: '100px',
                width: 'auto',
                marginLeft: '50px',
              }}
            />
          </LogoLink>
        </Box>

        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                color: theme.palette.brown,
                '&:hover': {
                  backgroundColor: theme.palette.olive,
                  color: theme.palette.sage,
                }
              }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box>
            {navItems.map((item) => (
              <StyledButton
                key={item.text}
                component={RouterLink}
                to={item.path}
              >
                {item.text}
              </StyledButton>
            ))}
          </Box>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
