import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTop: `1px solid ${theme.palette.sage}`,
  padding: theme.spacing(4, 0),
  marginTop: 'auto',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.brown,
  fontWeight: 400,
}));

const StyledRouterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const NewsletterBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  textAlign: 'center',
}));

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const handleSubscribe = async () => {
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Successfully subscribed to newsletter!',
          severity: 'success'
        });
        setEmail('');
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to subscribe. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <StyledFooter>
      <Container maxWidth="lg">
        <NewsletterBox>
          <Typography variant="h6" gutterBottom>
            Subscribe to Our Newsletter
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, maxWidth: 400, margin: '0 auto' }}>
            <TextField
              size="small"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ flexGrow: 1 }}
            />
            <Button
              variant="contained"
              onClick={handleSubscribe}
              disabled={!email}
            >
              Subscribe
            </Button>
          </Box>
        </NewsletterBox>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 1,
            mb: 2,
          }}
        >
          <FooterText variant="body2" align="center">
            {currentYear} Product of{' '}
            <StyledRouterLink to="/">
              Benaka IT Solutions
            </StyledRouterLink>
          </FooterText>
          <FooterText
            variant="body2"
            align="center"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            |
          </FooterText>
          <FooterText variant="body2" align="center">
            Kumta Karnataka, India
          </FooterText>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <StyledRouterLink to="/terms">
            Terms of Service
          </StyledRouterLink>
          <FooterText variant="body2" align="center">
            |
          </FooterText>
          <StyledRouterLink to="/privacy">
            Privacy Policy
          </StyledRouterLink>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
