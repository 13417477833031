import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ServicesSection from '../../components/sections/ServicesSection/ServicesSection';
import StatsSection from '../../components/sections/StatsSection/StatsSection';
import { MotionBox } from '../../components/common/Motion/Motion';
import SEO from '../../components/SEO/SEO';
import { getOrganizationSchema, getFAQSchema, getLocalBusinessSchema, getWebsiteSchema } from '../../utils/schema';

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.heroBg,
  padding: theme.spacing(4, 0),
}));

const HeroImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
  objectFit: 'cover',
  maxWidth: '600px',
  display: 'block',
  margin: '0 auto',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '500px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0',
  }
}));

const AnimationWrapper = styled(MotionBox)({});

const Home = () => {
  const faqs = [
    {
      question: "What data annotation services do you provide?",
      answer: "We provide comprehensive data annotation services including image annotation, text annotation, video annotation, and more for AI/ML applications."
    },
    {
      question: "How do you ensure data quality?",
      answer: "We maintain high data quality through our rigorous quality control process, experienced annotators, and multi-level review system."
    },
    {
      question: "What industries do you serve?",
      answer: "We serve various industries including healthcare, automotive, retail, agriculture, and technology sectors."
    }
  ];

  const schemas = [
    getOrganizationSchema(),
    getFAQSchema(faqs),
    getLocalBusinessSchema(),
    getWebsiteSchema()
  ];

  return (
    <>
      <SEO
        title="Benaka IT Solutions - Professional Data Annotation Services"
        description="Leading provider of reliable, efficient, and cost-effective data annotation services for AI/ML applications. Expert solutions for image, text, and video annotation."
        keywords="data annotation, AI solutions, ML solutions, image annotation, text annotation, video annotation, machine learning, artificial intelligence, data labeling, Kumta, Karnataka"
        schema={schemas}
        author="Benaka IT Solutions"
        publishedTime="2024-01-01T00:00:00+05:30"
        modifiedTime="2024-12-08T14:22:53+05:30"
      />
      
      <Box>
        <HeroSection>
          <Container>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <AnimationWrapper
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Typography variant="h2" color="brown" gutterBottom>
                    The Most Reliable, Efficient, and<br />
                    Cost-Effective Data Annotation Services
                  </Typography>

                </AnimationWrapper>

                <AnimationWrapper
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  <Typography variant="h6" color="olive" paragraph>
                    Empowering AI companies with high-quality data annotation and curation solutions.
                  </Typography>
                </AnimationWrapper>

                <AnimationWrapper
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                >

                </AnimationWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
              <AnimationWrapper
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.6 }}
                >
                  {/* Add hero image with animation */}
                  <HeroImage
                    src="/data-annotation-hero.jpg" 
                    alt="Hero Image"
                  />
                </AnimationWrapper>
              </Grid>
            </Grid>
          </Container>
        </HeroSection>

        <StatsSection />
        <ServicesSection />
      </Box>
    </>
  );
};

export default Home;
