import React from 'react';
import { Container } from '@mui/material';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { styled } from '@mui/material/styles';

const LayoutWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const MainContent = styled('main')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutWrapper>
      <Header />
      <MainContent>
        {children}
      </MainContent>
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
