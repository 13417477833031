export const getOrganizationSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Benaka IT Solutions',
  url: 'https://benaka.ai',
  logo: 'https://benaka.ai/Logo.png',
  description: 'Leading provider of data annotation, AI/ML solutions, and digital transformation services.',
  sameAs: [
    'https://www.linkedin.com/company/benaka-it-solutions',
    'https://twitter.com/BenakaIT',
    // Add other social media profiles
  ],
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Kumta',
    addressRegion: 'Karnataka',
    addressCountry: 'India'
  },
  contactPoint: [{
    '@type': 'ContactPoint',
    telephone: '+91-XXX-XXX-XXXX',
    contactType: 'customer service',
    email: 'contact@benaka.ai',
    availableLanguage: ['English', 'Hindi', 'Kannada']
  }],
  areaServed: {
    '@type': 'GeoCircle',
    geoMidpoint: {
      '@type': 'GeoCoordinates',
      latitude: '14.4273',
      longitude: '74.4137'
    },
    geoRadius: '50000'
  }
});

export const getLocalBusinessSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Benaka IT Solutions',
  image: 'https://benaka.ai/Logo.png',
  '@id': 'https://benaka.ai',
  url: 'https://benaka.ai',
  telephone: '+91-XXX-XXX-XXXX',
  priceRange: '₹₹',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Your Street Address',
    addressLocality: 'Kumta',
    addressRegion: 'Karnataka',
    postalCode: 'XXXXXX',
    addressCountry: 'IN'
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: '14.4273',
    longitude: '74.4137'
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday'
    ],
    opens: '09:00',
    closes: '18:00'
  }
});

export const getWebsiteSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Benaka IT Solutions',
  url: 'https://benaka.ai',
  potentialAction: {
    '@type': 'SearchAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: 'https://benaka.ai/search?q={search_term_string}'
    },
    'query-input': 'required name=search_term_string'
  }
});

export const getServiceSchema = (service: {
  name: string;
  description: string;
  image?: string;
  url?: string;
  provider?: string;
  areaServed?: string;
  serviceType?: string;
}) => ({
  '@context': 'https://schema.org',
  '@type': 'Service',
  provider: {
    '@type': 'Organization',
    name: service.provider || 'Benaka IT Solutions',
    url: 'https://benaka.ai'
  },
  name: service.name,
  description: service.description,
  image: service.image || 'https://benaka.ai/Logo.png',
  url: service.url || 'https://benaka.ai/services',
  areaServed: service.areaServed || 'Worldwide',
  serviceType: service.serviceType || 'Data Annotation Service',
  termsOfService: 'https://benaka.ai/terms',
  brand: {
    '@type': 'Brand',
    name: 'Benaka IT Solutions'
  }
});

export const getBreadcrumbSchema = (items: Array<{ name: string; url: string }>) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: items.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: item.name,
    item: `https://benaka.ai${item.url}`
  }))
});

export const getFAQSchema = (faqs: Array<{ question: string; answer: string }>) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqs.map(faq => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.answer
    }
  }))
});
